/********************************************************************************
	Layout
 *******************************************************************************/

.on-mobile {
	display: none !important;

	.breakpoint-small & {
		display: block !important;
	}
}

.on-mobile-flex {
	display: none !important;

	.breakpoint-small & {
		display: flex !important;
	}
}

.on-not-mobile {
	.breakpoint-small & {
		display: none !important;
	}
}

body {
	position: relative;
	background: $main-bg;
}

.wrap {
	min-height: 100vh;
	padding: $spacing-medium;

	.breakpoint-medium-small &,
	.breakpoint-small & {
		padding: 0;
	}

	> .-inner {
		overflow: hidden;
		display: flex;
		height: 100%;
		min-height: calc( 100vh - #{$spacing-medium * 2});
		border-radius: $border-radius-base;
		background: #fff;

		> main {
			flex: 0 0 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: $spacing-content;

			.breakpoint-large-max & {
				flex: 0 0 100%;
			}

			.login {
				width: 100%;
				max-width: 350px;

				.logo {
					width: 80%;
					height: auto;
				}
			}
		}

		> aside {
			flex: 0 0 50%;
			display: flex;
			flex-direction: column;

			.breakpoint-large-max & {
				display: none;
			}

			.-bg {
				flex: 1 0 auto;
				position: relative;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}

			.-text {
				padding: $spacing-content;
				background: $brand-primary;

				p {
					max-width: 500px;
					text-align: center;
					color: #fff;
				}
			}
		}
	}
}

.login {
	text-align: center;

	p {
		text-align: center;
	}
}
