/********************************************************************************
    Layout
 *******************************************************************************/
$c-grid-column-width:               14.285714285714285714285714285714%;
$c-grid-work-column-width:          16.66666666666667%;

$max-width:                         1600px;

$header-secondary-height:           80px;
$main-margin-top:                   45px;
$post-content-padding:              30px;
$post-content-padding-small:        15px;
$post-content-padding-xsmall:       8px;
$post-content-padding-tiny:         5px;


/********************************************************************************
    Margin / Padding
 *******************************************************************************/
$spacing-base:                      15px;
$spacing-tiny:                      5px;
$spacing-xsmall:                    8px;
$spacing-small:                     10px;
$spacing-medium:                    30px;
$spacing-large:                     60px;
$spacing-xlarge:                    90px;
$spacing-xxlarge:                   120px;

$spacing-type:                      20px;

$spacing-tile:                      15px;
$spacing-tile-grid:                 3px;
$spacing-tile-double:               $spacing-tile * 2;
$spacing-tile-grid-double:          $spacing-tile-grid * 2;

$spacing-content-large-top:         $spacing-large;
$spacing-content-large-bottom:      $spacing-large;
$spacing-content-small-top:         $post-content-padding-small;
$spacing-content-small-bottom:      $post-content-padding-small;
$spacing-content-xsmall-top:        $post-content-padding-xsmall;
$spacing-content-xsmall-bottom:     $post-content-padding-xsmall;
$spacing-content-tiny-top:          $post-content-padding-tiny;
$spacing-content-tiny-bottom:       $post-content-padding-tiny;

$spacing-content-top:               $post-content-padding - 6px;
$spacing-content-bottom:            $post-content-padding - 9px;
$spacing-content-sides:             $post-content-padding;

$spacing-content-medium-top:        $spacing-medium;
$spacing-content-medium-bottom:     $spacing-medium;

$spacing-content:                   $spacing-content-top $spacing-content-sides $spacing-content-bottom;
$spacing-content-gap-top:           6px;
$spacing-content-gap-bottom:        9px;
$spacing-content-gap-sides:         0;
$spacing-content-gap:               $spacing-content-gap-top $spacing-content-gap-sides $spacing-content-gap-bottom;

$spacing-content-neg-top:           -$spacing-content-top;
$spacing-content-neg-bottom:        -$spacing-content-bottom;
$spacing-content-neg-sides:         -$spacing-content-sides;
$spacing-content-neg:               $spacing-content-neg-top $spacing-content-neg-sides $spacing-content-neg-bottom;

$spacing-content-medium-top:         $spacing-medium - 6px;
$spacing-content-medium-bottom:      $spacing-medium - 9px;
$spacing-content-medium-sides:       $spacing-medium;
$spacing-content-medium:             $spacing-content-medium-top $spacing-content-medium-sides $spacing-content-medium-bottom;

$spacing-content-large-top:         $spacing-large - 6px;
$spacing-content-large-bottom:      $spacing-large - 9px;
$spacing-content-large-sides:       $spacing-large;
$spacing-content-large:             $spacing-content-large-top $spacing-content-large-sides $spacing-content-large-bottom;

$spacing-content-small-top:          $post-content-padding-small - 4px;
$spacing-content-small-bottom:       $post-content-padding-small - 4px;
$spacing-content-small-sides:        $post-content-padding-small;
$spacing-content-small:              $spacing-content-small-top $spacing-content-small-sides $spacing-content-small-bottom;

$spacing-content-small-neg-top:     -$spacing-content-small-top;
$spacing-content-small-neg-bottom:  -$spacing-content-small-bottom;
$spacing-content-small-neg-sides:   -$spacing-content-small-sides;
$spacing-content-small-neg:         $spacing-content-small-neg-top $spacing-content-small-neg-sides $spacing-content-small-neg-bottom;

$spacing-content-xsmall-top:         $post-content-padding-xsmall + 3px;
$spacing-content-xsmall-bottom:      $post-content-padding-xsmall;
$spacing-content-xsmall-sides:       $post-content-padding-xsmall;
$spacing-content-xsmall:             $spacing-content-xsmall-top $spacing-content-xsmall-sides $spacing-content-xsmall-bottom;

$spacing-content-tiny-top:           $post-content-padding-tiny - 1px;
$spacing-content-tiny-bottom:        $post-content-padding-tiny - 4px;
$spacing-content-tiny-sides:         $post-content-padding-tiny;
$spacing-content-tiny:               $spacing-content-tiny-top $spacing-content-tiny-sides $spacing-content-tiny-bottom;

// $spacing-block-link:                 11px 15px 8px;
$spacing-block-link:                 7px 15px 4px;
$spacing-block-link-top:             7px;
$spacing-block-link-sides:           15px;

$line-height-base:                   1.618;


/********************************************************************************
    Radius
 *******************************************************************************/
$border-radius-base:        5px;
$border-radius-medium:      10px;
$border-radius-small:       2px;


/********************************************************************************
    Foundation
 *******************************************************************************/
$global-text-direction:     ltr !default;
$global-flexbox:            true !default;

$global-left:               if($global-text-direction == rtl, right, left);
$global-right:              if($global-text-direction == rtl, left, right);

$grid-column-count:         12;
$grid-column-gutter: (
                            small: 20px,
                            medium: 30px,
);

$grid-column-align-edge:    true;
$block-grid-max:            8;

$spacing-gutter:            30px;
$spacing-gutter-half:       15px;

$spacing-gutter-small:      20px;
$spacing-gutter-half-small: 10px;
