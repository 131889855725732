$text-max-width: 650px;

/********************************************************************************
	Rendering
 *******************************************************************************/
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}


/********************************************************************************
	Base Sizes & Fonts
 *******************************************************************************/
body {
	font-family: $font-family-base;
	font-size: 15.5px;
	color: $brand-text;
}

h1, h2, h3, h4, h5, h6 {
	margin-left: auto;
	margin-right: auto;
	line-height: 1.5;
	font-family: $font-family-headings;
	font-weight: 200;
	color: $brand-headings;
	font-feature-settings: "lnum" 1;

	@supports ( font-variant-numeric: oldstyle-nums ) {
		font-feature-settings: normal;
		font-variant-numeric: lining-nums;
	}

	strong {
		font-weight: 200;
	}
}

@mixin p {
	margin-left: auto;
	margin-right: auto;
	line-height: 1.5;
	font-family: $font-family-base;
	font-weight: 400;
	font-size: 16px;
	// font-size: 17px;
	font-feature-settings: "onum" 1;

	@supports ( font-variant-numeric: oldstyle-nums ) {
		font-feature-settings: normal;
		font-variant-numeric: oldstyle-nums;
	}

	.breakpoint-medium & {
		font-size: 15.5px;
	}

	.breakpoint-small & {
		font-size: 14px;
	}
}

p, .p, ol, ul, li, dt, table, input, textarea {
	@include p;
}

// p:empty {
// 	&:after {
// 		content: "\00a0";
// 	}
// }

input {
	.breakpoint-small & {
		font-size: 16px;
	}
}

span {
	font-family: inherit;
}

b, strong {
	font-weight: 500;
}


/********************************************************************************
	Base Margins
 *******************************************************************************/
h1, h2, h3, h4, h5, h6 {
	clear: both;
}

* {
	+ p, + ol, + ul, + dl, + table, + .-text-wrap {
		margin-top: $spacing-type;
	}

	+ p {
		&.sub {
			margin-top: 5px;
		}
	}
}
p, ol, ul, dl, table {
	& + * {
		margin-top: $spacing-type;

		.breakpoint-small & {
			margin-top: $spacing-base;
		}
	}
}

* + h1 {
	margin-top: $spacing-type;
}
h1 {
	font-size: 30px;

	& + * {
		margin-top: $spacing-type;
	}

	& + .sub {
		margin-top: 0;
	}

	.breakpoint-small & {
		font-size: 21px;

		& + * {
			margin-top: $spacing-base;
		}
	}
}

* + h2 {
	margin-top: $spacing-type + $spacing-base;

	&.sub {
		margin-top: 0;
	}
}
.sup + h2 {
	margin-top: 0;
}
h2 {
	font-size: 28px;

	.content-body & {
		font-size: 25px;
	}

	& + * {
		margin-top: $spacing-type;
	}

	.breakpoint-small & {
		font-size: 18px;

		& + * {
			margin-top: $spacing-base;
		}
	}
}

* + h3 {
	margin-top: $spacing-medium;

	&.sub {
		margin-top: 5px;
	}
}
h3 {
	font-size: 26px;

	.content-body & {
		font-size: 23px;
	}

	& + * {
		margin-top: $spacing-base;
	}

	.breakpoint-small & {
		font-size: 16px;

		& + * {
			margin-top: $spacing-small;
		}
	}
}

* + h4 {
	margin-top: $spacing-medium;

	&.sub {
		margin-top: 5px;
	}
}
h4 {
	font-size: 24px;

	.content-body & {
		font-size: 21px;
	}

	& + * {
		margin-top: 5px;
	}

	.breakpoint-small & {
		font-size: 16px;

		& + * {
			margin-top: $spacing-small;
		}
	}
}

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
a {
	cursor: pointer;
	border-bottom: 1px solid transparent;
	color: $brand-link;
	text-decoration: none;
	transition: all .2s 0s;
}

a:visited {
	color: $brand-primary;
}

a:hover,
a:focus,
a:active {
	color: $brand-link-hover;
	border-color: $brand-link-hover;
}

a:focus {
	outline: thin dotted;
}

a:hover,
a:active {
	outline: 0;
}

/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/
ul, ol {
	list-style-position: outside;
	margin-left: auto;
	margin-right: auto;
}

ul {
	list-style-type: disc;
}

ol {
	list-style-type: decimal;
}

li > ul,
li > ol {
	margin-top: $spacing-base;
	margin-bottom: 0;
	margin-left: $spacing-medium;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 $spacing-medium;
}


/*--------------------------------------------------------------
# Misc
--------------------------------------------------------------*/
.accent {
	color: $brand-secondary;
}

mark {
	color: $mark-color;
	background: $mark-background-color;
}

sup, sub {
	line-height: 0;
	font-size: 14px;
}
