/********************************************************************************
	Transitions
 *******************************************************************************/
$transition-time-base: 					.2s 0s;
$transition-time-short: 				.15s 0s;
$transition-time-medium: 				.4s 0s;

$transition-base: 					 	all $transition-time-base;
$transition-base-short: 				all $transition-time-short;
$transition-base-medium: 				all $transition-time-medium;
