.login-form {
	position: relative;

	.-inner {
		.with-sso & {
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.3s;
		}

		.is-finished-auth-check & {
			opacity: 1;
			pointer-events: all;
		}
	}

	input, button {
		margin: 0;
	}
}

.authenticating {
	z-index: 10;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 70px;
	margin: auto;
	transition: opacity 0.3s;

	.is-finished-auth-check & {
		opacity: 0;
		pointer-events: none;
	}

	.-loader {
		width: 70px;
		margin: 0 auto 30px;

		> div {
			display: inline-block;
			width: 18px;
			height: 18px;
			border-radius: 100%;
			background: #f26700;
			animation: login-bouncedelay 1.4s infinite ease-in-out both;
		}

		.-a {
			animation-delay: -0.32s;
		}

		.-b {
			animation-delay: -0.16s;
		}

		.-c {

		}
	}
}

@keyframes login-bouncedelay {
	0%, 80%, 100% {
		transform: scale( 0 );
	} 40% {
		transform: scale( 1.0 );
	}
}
