/********************************************************************************
    Colours
 *******************************************************************************/
$adventist-flame:               #f07d00;
$adventist-flame-alt:           #f26700;
$adventist-charcoal:            #39383A;
$adventist-excellence:          #1441AA;
$adventist-excellence-alt:      #2f5690;
$adventist-calm:                #00CFC8;
$adventist-health-blue:         #09b5cd;
$adventist-verve:               #73B941;
$adventist-compassion:          #A82361;
$adventist-gold:                #ebb113;
$adventist-ming:                #3e8391;
$adventist-pure-white:          #fff;
$adventist-pewter:              #969698;
$adventist-onyx:                #000;

$gradient-shade:                linear-gradient( 180deg, transparent, rgba( 0, 0, 0, 0.09 ) );
$gradient-shade-lighter:        linear-gradient( 180deg, transparent, rgba( 0, 0, 0, 0.02 ) );
$gradient-gray-lighter:         linear-gradient( 180deg, transparent, rgba( 0, 0, 0, 0.03 ) );
$gradient-adventist-flame:      linear-gradient( 130deg, $adventist-flame, #e2491b );

$gray-base:                     #000;
$gray-darker:                   lighten($gray-base, 13.5%);
$gray-dark:                     lighten($gray-base, 28%);
$gray:                          #A8A9AC;
$gray-light:                    #E5E5E5;
$gray-lighter:                  #F5F5F5;

$main-bg:                       #eee;

$sub-on-white:                  #9c9c9c;
$subdued-text-on-white:         #848484;
$sub-on-gray:                   #949494;
$subdued-text-on-gray:          #838e90;
$secondary-text:                #5d5d5d;
$secondary-bg-alt:              #f7f7f7;
$secondary-border:              #ecf0f3;

$brand-primary:                 $adventist-ming;
$brand-secondary:               #0084b2;
$brand-tertiary:                #5b6c87;

$brand-primary-dark:            darken( $brand-primary, 5% );
$brand-secondary-dark:          darken( $brand-secondary, 5% );
$brand-tertiary-dark:           darken( $brand-tertiary, 5% );

$brand-secondary-dark-border:   darken( $brand-secondary-dark, 5% );

$brand-dark:                    $brand-tertiary;
$brand-highlight:               #ffff99;

$brand-success:                 #5cb85c;
$brand-info:                    #5bc0de;
$brand-warning:                 #f0ad4e;
$brand-danger:                  #d9534f;

$brand-text:                    #6a6e6f;
$brand-text:                    #777f82;
$brand-text-alt:                #8e8e8e;
$brand-headings:                #5f6667;
$brand-border-color:            #5C96C3;
$brand-bg-dark:                 #45474D;

$brand-link:                    $brand-primary;
$brand-link-hover:              #e28116;

$button-bg-primary:             $brand-primary;
$button-bg-primary-hover:       darken( $brand-primary, 5% );

$button-bg-alt:                 #066F97;
$button-bg-alt-hover:           #045777;

$selection-background-color:    $brand-primary;
$selection-color:               #fff;

$mark-background-color:         #ffff99;
$mark-color:                    $brand-text;

$box-shadow:                    0px 1px 10px rgba(6, 83, 148, 0.1);
$box-shadow-dark:               0px 2px 6px rgba(6, 83, 148, 0.15);
$box-shadow-dark-hover:         0px 5px 10px rgba(6, 83, 148, 0.2);
$box-shadow-hover:              2px 5px 5px rgba(6, 83, 148, 0.13);

$form-label-color:              #d6760e;
$form-input-text:               #666;
$form-input-bg:                 #fbfbfb;
$form-input-border-color:       rgba(202, 202, 202, 0.64);
$form-input-border-color-hover: rgba(202, 202, 202, 1);
$form-input-border-color-focus: darken( $form-input-border-color, 25% );
$form-input-border:             1px solid $form-input-border-color;
$form-input-border-hover:       1px solid $form-input-border-color-hover;
$form-input-border-focus:       1px solid $form-input-border-color-focus;

$colour-facebook:               #3b5998;
$colour-twitter:                #4099ff;
$colour-pinterest:              #bd081c;
$colour-google:                 #d62d20;

$editor-bg:                     #f1f1f1;
