@font-face {
    font-family: "Din Next W01";
    src: url("../fonts/din-next/de1216be-00c2-43c0-9c45-3e7f925eb519.woff2") format("woff2"),
         url("../fonts/din-next/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Din Next W01";
    src: url("../fonts/din-next/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"),
         url("../fonts/din-next/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Din Next W01";
    src: url("../fonts/din-next/be607c9f-3c81-41b4-b7ad-17af005c80bb.woff2") format("woff2"),
         url("../fonts/din-next/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
