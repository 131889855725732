/********************************************************************************
	Forms
 *******************************************************************************/

$spacing-input-sides: 13px;
$spacing-textarea-padding: 7px $spacing-input-sides 8px;
$spacing-input-padding: 9px $spacing-input-sides 6px;
$spacing-input-padding-select: 7px 30px 8px $spacing-input-sides;
$spacing-input-padding-small: 5px 10px 6px;

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
}

.form-select {
	position: relative;
	width: 100%;
	max-width: 200px;

	.breakpoint-small & {
		max-width: none;
	}

	i {
		pointer-events: none;
		position: absolute;
		top: 12px;
		right: $spacing-input-sides;
	}

	select {
		width: 100%;
		max-width: 200px;
		min-height: 40px;

		.breakpoint-small & {
			max-width: none;
		}
	}
}

.form-checkbox {
	display: flex;
	margin-bottom: -3px;

	+ .form-checkbox {
		margin-top: $spacing-base;
	}

	input {
		margin-right: $spacing-small;
	}

	label {
		top: -2px;
		padding-bottom: 0;
	}
}

button {
	cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
	display: block;
	width: 100%;
	max-width: none;
	margin: 0;
	border: $form-input-border;
	border-radius: $border-radius-base;
	font-size: 17px;
	font-family: $font-family-base;
	color: $form-input-text;
	background: $form-input-bg;
	transition: $transition-base;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:hover {
		border: $form-input-border-hover;
	}

	&:focus {
		outline: none;
		border: $form-input-border-focus;
	}

	&.input-group-field {
		border-radius: 0;
	}

	.breakpoint-small & {
		font-size: 16px;
	}
}

select {
	cursor: pointer;
	padding: $spacing-input-padding-select;
	border: $form-input-border;
	border-radius: $border-radius-small;
	font-family: $font-family-base;
	font-size: 14px;
	background: $form-input-bg;
	transition: $transition-base;
	-webkit-appearance: none;
	-moz-appearance: none;

	&:hover {
		border: $form-input-border-hover;
	}

	&:focus {
		outline: none;
		border: $form-input-border-focus;
	}

	.breakpoint-small & {
		padding: $spacing-input-padding-small;
		font-size: 16px;
	}
}


input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
	padding: $spacing-input-padding;

	.breakpoint-small & {
		padding: $spacing-input-padding-small;
	}
}

// Prevent Chrome Yellow BG for Auto Fill
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $form-input-bg inset;
    color: $form-input-text !important;
}

input[type="checkbox"] {
	margin-right: $spacing-small;
}

textarea {
	padding: $spacing-textarea-padding;
	// padding-left: $spacing-input-sides;
	// padding-right: $spacing-input-sides;
	width: 100%;
}

label {
	display: block;
	position: relative;
	top: -4px;
	padding: 0 0 3px;
	border-radius: $border-radius-base;
	font-size: 12px;
	font-family: $font-family-base;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0.03em;
	color: $form-label-color;

	.recaptcha & {
		display: none;
	}
}

.form-item {
	position: relative;

	+ .form-item {
		margin-top: $spacing-base;
	}
}


/********************************************************************************
	Formidable Forms Plugin Form Classes
 *******************************************************************************/

.frm_form_field {
	position: relative;
	float: left;
	width: 100%;

	+ .frm_form_field,
	+ .frm_submit {
		margin-top: $spacing-medium;
	}

	label {
		margin-bottom: 0;
		user-select: none;
		cursor: pointer;
	}

	.frm_required {
		color: $subdued-text-on-white;
	}

	&.frm_blank_field .frm_required,
	.-with-errors & .frm_required {
		color: $brand-danger;
	}

	.frm_error {
		display: none;
	}
}

input[type=hidden] + .frm_submit {
	margin-top: $spacing-base;
}

.frm_opt_container {
	margin-top: 7px;

	.frm_radio {
		label {
			margin: 0 0 5px;

			input {
				margin: 0 $spacing-small 0 0;
			}
		}
	}
}

.frm_submit {
	float: left;
	width: 100%;
}

.field-hidden,
.frm_hidden {
	display: none;
}

.frm_half,
.frm_fourth {
	float: left;
}

.frm_half,
.frm_fourth {
	& + & {
		padding-left: $spacing-base;
	}

	&:first-of-type + & {
		margin-top: 0;
	}

	&.frm_first {
		padding-left: 0;

		.frm_form_field + & {
			margin-top: $spacing-base;
		}
	}
}

.frm_half {
	width: 50%;

	.breakpoint-small & {
		width: 100%;
		padding-left: 0;
	}

	.breakpoint-small :first-of-type + & {
		margin-top: $spacing-base;
	}
}

.frm_fourth {
	width: 25%;

	.breakpoint-small & {
		width: 100%;
		padding-left: 0;
	}

	.breakpoint-small :first-of-type + & {
		margin-top: $spacing-base;
	}
}
