/********************************************************************************
	Fonts
 *******************************************************************************/

$font-family-base: 'Din Next W01', sans-serif;
$font-family-menu: 'Din Next W01', sans-serif;
$font-family-label: 'Din Next W01', sans-serif;
$font-family-input: 'Din Next W01', sans-serif;

$font-family-headings: 'PF Din Display W01', sans-serif;
