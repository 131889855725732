/********************************************************************************
    Colours
 *******************************************************************************/
/********************************************************************************
	Fonts
 *******************************************************************************/
/********************************************************************************
    Layout
 *******************************************************************************/
/********************************************************************************
    Margin / Padding
 *******************************************************************************/
/********************************************************************************
    Radius
 *******************************************************************************/
/********************************************************************************
    Foundation
 *******************************************************************************/
/********************************************************************************
	Breakpoints
 *******************************************************************************/
/********************************************************************************
	Transitions
 *******************************************************************************/
/*! sanitize.css v3.3.0 | CC0 1.0 Public Domain | github.com/10up/sanitize.css */
/*
 * Normalization
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted; }

audio:not([controls]) {
  display: none; }

b,
strong {
  font-weight: bolder; }

button {
  -webkit-appearance: button;
  overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

button,
select {
  text-transform: none; }

details {
  display: block; }

hr {
  overflow: visible; }

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%; }

input {
  -webkit-border-radius: 0; }
  input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button; }
  input[type="number"] {
    width: auto; }
  input[type="search"] {
    -webkit-appearance: textfield; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }

main {
  display: block; }

pre {
  overflow: auto; }

progress {
  display: inline-block; }

summary {
  display: block; }

svg:not(:root) {
  overflow: hidden; }

template {
  display: none; }

textarea {
  overflow: auto; }

[hidden] {
  display: none; }

/*
 * Universal inheritance
 */
*,
::before,
::after {
  box-sizing: inherit; }

* {
  font-size: inherit;
  line-height: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit; }

/*
 * Opinionated defaults
 */
* {
  margin: 0;
  padding: 0; }

*,
::before,
::after {
  border-style: solid;
  border-width: 0; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

select {
  -moz-appearance: none;
  -webkit-appearance: none; }
  select::-ms-expand {
    display: none; }
  select::-ms-value {
    color: currentColor; }

svg {
  fill: currentColor; }

[aria-busy="true"] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

[hidden][aria-hidden="false"] {
  clip: rect(0 0 0 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden="false"]:focus {
    clip: auto; }

/*
 * Configurable defaults
 */
* {
  background-repeat: no-repeat; }

:root {
  background-color: #fff;
  box-sizing: border-box;
  color: #555;
  cursor: default;
  font: 16px/1.5 sans-serif; }

a {
  text-decoration: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit; }

button,
[type="button"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="reset"],
[type="search"],
[type="submit"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  min-height: 1.5em; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; }

nav ol,
nav ul {
  list-style: none; }

small {
  font-size: 75%; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

textarea {
  resize: vertical; }

::-moz-selection {
  background-color: #3e8391;
  color: #fff;
  text-shadow: none; }

::selection {
  background-color: #3e8391;
  color: #fff;
  text-shadow: none; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  height: 100%; }

@font-face {
  font-family: "Din Next W01";
  src: url("../fonts/din-next/de1216be-00c2-43c0-9c45-3e7f925eb519.woff2") format("woff2"), url("../fonts/din-next/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "Din Next W01";
  src: url("../fonts/din-next/44e7b0fa-6c8d-43c2-b19e-f1e3ce9ea57c.woff2") format("woff2"), url("../fonts/din-next/c5a7f89e-15b6-49a9-8259-5ea665e72191.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Din Next W01";
  src: url("../fonts/din-next/be607c9f-3c81-41b4-b7ad-17af005c80bb.woff2") format("woff2"), url("../fonts/din-next/4bf0ead4-e61b-4992-832b-6ff05828d99f.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

/********************************************************************************
	Rendering
 *******************************************************************************/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

/********************************************************************************
	Base Sizes & Fonts
 *******************************************************************************/
body {
  font-family: "Din Next W01", sans-serif;
  font-size: 15.5px;
  color: #777f82; }

h1, h2, h3, h4, h5, h6 {
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  font-family: "PF Din Display W01", sans-serif;
  font-weight: 200;
  color: #5f6667;
  font-feature-settings: "lnum" 1; }
  @supports (font-variant-numeric: oldstyle-nums) {
    h1, h2, h3, h4, h5, h6 {
      font-feature-settings: normal;
      font-variant-numeric: lining-nums; } }
  h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
    font-weight: 200; }

p, .p, ol, ul, li, dt, table, input, textarea {
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
  font-family: "Din Next W01", sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-feature-settings: "onum" 1; }
  @supports (font-variant-numeric: oldstyle-nums) {
    p, .p, ol, ul, li, dt, table, input, textarea {
      font-feature-settings: normal;
      font-variant-numeric: oldstyle-nums; } }
  .breakpoint-medium p, .breakpoint-medium .p, .breakpoint-medium ol, .breakpoint-medium ul, .breakpoint-medium li, .breakpoint-medium dt, .breakpoint-medium table, .breakpoint-medium input, .breakpoint-medium textarea {
    font-size: 15.5px; }
  .breakpoint-small p, .breakpoint-small .p, .breakpoint-small ol, .breakpoint-small ul, .breakpoint-small li, .breakpoint-small dt, .breakpoint-small table, .breakpoint-small input, .breakpoint-small textarea {
    font-size: 14px; }

.breakpoint-small input {
  font-size: 16px; }

span {
  font-family: inherit; }

b, strong {
  font-weight: 500; }

/********************************************************************************
	Base Margins
 *******************************************************************************/
h1, h2, h3, h4, h5, h6 {
  clear: both; }

* + p, * + ol, * + ul, * + dl, * + table, * + .-text-wrap {
  margin-top: 20px; }

* + p.sub {
  margin-top: 5px; }

p + *, ol + *, ul + *, dl + *, table + * {
  margin-top: 20px; }
  .breakpoint-small p + *, .breakpoint-small ol + *, .breakpoint-small ul + *, .breakpoint-small dl + *, .breakpoint-small table + * {
    margin-top: 15px; }

* + h1 {
  margin-top: 20px; }

h1 {
  font-size: 30px; }
  h1 + * {
    margin-top: 20px; }
  h1 + .sub {
    margin-top: 0; }
  .breakpoint-small h1 {
    font-size: 21px; }
    .breakpoint-small h1 + * {
      margin-top: 15px; }

* + h2 {
  margin-top: 35px; }
  * + h2.sub {
    margin-top: 0; }

.sup + h2 {
  margin-top: 0; }

h2 {
  font-size: 28px; }
  .content-body h2 {
    font-size: 25px; }
  h2 + * {
    margin-top: 20px; }
  .breakpoint-small h2 {
    font-size: 18px; }
    .breakpoint-small h2 + * {
      margin-top: 15px; }

* + h3 {
  margin-top: 30px; }
  * + h3.sub {
    margin-top: 5px; }

h3 {
  font-size: 26px; }
  .content-body h3 {
    font-size: 23px; }
  h3 + * {
    margin-top: 15px; }
  .breakpoint-small h3 {
    font-size: 16px; }
    .breakpoint-small h3 + * {
      margin-top: 10px; }

* + h4 {
  margin-top: 30px; }
  * + h4.sub {
    margin-top: 5px; }

h4 {
  font-size: 24px; }
  .content-body h4 {
    font-size: 21px; }
  h4 + * {
    margin-top: 5px; }
  .breakpoint-small h4 {
    font-size: 16px; }
    .breakpoint-small h4 + * {
      margin-top: 10px; }

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
a {
  cursor: pointer;
  border-bottom: 1px solid transparent;
  color: #3e8391;
  text-decoration: none;
  transition: all .2s 0s; }

a:visited {
  color: #3e8391; }

a:hover,
a:focus,
a:active {
  color: #e28116;
  border-color: #e28116; }

a:focus {
  outline: thin dotted; }

a:hover,
a:active {
  outline: 0; }

/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/
ul, ol {
  list-style-position: outside;
  margin-left: auto;
  margin-right: auto; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

li > ul,
li > ol {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 30px; }

dt {
  font-weight: bold; }

dd {
  margin: 0 30px; }

/*--------------------------------------------------------------
# Misc
--------------------------------------------------------------*/
.accent {
  color: #0084b2; }

mark {
  color: #777f82;
  background: #ffff99; }

sup, sub {
  line-height: 0;
  font-size: 14px; }

/********************************************************************************
	Forms
 *******************************************************************************/
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  background: none; }

.form-select {
  position: relative;
  width: 100%;
  max-width: 200px; }
  .breakpoint-small .form-select {
    max-width: none; }
  .form-select i {
    pointer-events: none;
    position: absolute;
    top: 12px;
    right: 13px; }
  .form-select select {
    width: 100%;
    max-width: 200px;
    min-height: 40px; }
    .breakpoint-small .form-select select {
      max-width: none; }

.form-checkbox {
  display: flex;
  margin-bottom: -3px; }
  .form-checkbox + .form-checkbox {
    margin-top: 15px; }
  .form-checkbox input {
    margin-right: 10px; }
  .form-checkbox label {
    top: -2px;
    padding-bottom: 0; }

button {
  cursor: pointer; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  display: block;
  width: 100%;
  max-width: none;
  margin: 0;
  border: 1px solid rgba(202, 202, 202, 0.64);
  border-radius: 5px;
  font-size: 17px;
  font-family: "Din Next W01", sans-serif;
  color: #666;
  background: #fbfbfb;
  transition: all 0.2s 0s;
  -webkit-appearance: none;
  -moz-appearance: none; }
  input[type="text"]:hover,
  input[type="email"]:hover,
  input[type="password"]:hover,
  input[type="number"]:hover,
  textarea:hover {
    border: 1px solid #cacaca; }
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="number"]:focus,
  textarea:focus {
    outline: none;
    border: 1px solid rgba(138, 138, 138, 0.64); }
  input[type="text"].input-group-field,
  input[type="email"].input-group-field,
  input[type="password"].input-group-field,
  input[type="number"].input-group-field,
  textarea.input-group-field {
    border-radius: 0; }
  .breakpoint-small input[type="text"], .breakpoint-small
  input[type="email"], .breakpoint-small
  input[type="password"], .breakpoint-small
  input[type="number"], .breakpoint-small
  textarea {
    font-size: 16px; }

select {
  cursor: pointer;
  padding: 7px 30px 8px 13px;
  border: 1px solid rgba(202, 202, 202, 0.64);
  border-radius: 2px;
  font-family: "Din Next W01", sans-serif;
  font-size: 14px;
  background: #fbfbfb;
  transition: all 0.2s 0s;
  -webkit-appearance: none;
  -moz-appearance: none; }
  select:hover {
    border: 1px solid #cacaca; }
  select:focus {
    outline: none;
    border: 1px solid rgba(138, 138, 138, 0.64); }
  .breakpoint-small select {
    padding: 5px 10px 6px;
    font-size: 16px; }

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"] {
  padding: 9px 13px 6px; }
  .breakpoint-small input[type="text"], .breakpoint-small
  input[type="email"], .breakpoint-small
  input[type="password"], .breakpoint-small
  input[type="number"] {
    padding: 5px 10px 6px; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fbfbfb inset;
  color: #666 !important; }

input[type="checkbox"] {
  margin-right: 10px; }

textarea {
  padding: 7px 13px 8px;
  width: 100%; }

label {
  display: block;
  position: relative;
  top: -4px;
  padding: 0 0 3px;
  border-radius: 5px;
  font-size: 12px;
  font-family: "Din Next W01", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #d6760e; }
  .recaptcha label {
    display: none; }

.form-item {
  position: relative; }
  .form-item + .form-item {
    margin-top: 15px; }

/********************************************************************************
	Formidable Forms Plugin Form Classes
 *******************************************************************************/
.frm_form_field {
  position: relative;
  float: left;
  width: 100%; }
  .frm_form_field + .frm_form_field,
  .frm_form_field + .frm_submit {
    margin-top: 30px; }
  .frm_form_field label {
    margin-bottom: 0;
    user-select: none;
    cursor: pointer; }
  .frm_form_field .frm_required {
    color: #848484; }
  .frm_form_field.frm_blank_field .frm_required,
  .-with-errors .frm_form_field .frm_required {
    color: #d9534f; }
  .frm_form_field .frm_error {
    display: none; }

input[type=hidden] + .frm_submit {
  margin-top: 15px; }

.frm_opt_container {
  margin-top: 7px; }
  .frm_opt_container .frm_radio label {
    margin: 0 0 5px; }
    .frm_opt_container .frm_radio label input {
      margin: 0 10px 0 0; }

.frm_submit {
  float: left;
  width: 100%; }

.field-hidden,
.frm_hidden {
  display: none; }

.frm_half,
.frm_fourth {
  float: left; }

.frm_half + .frm_half,
.frm_fourth + .frm_half, .frm_half +
.frm_fourth,
.frm_fourth +
.frm_fourth {
  padding-left: 15px; }

.frm_half:first-of-type + .frm_half,
.frm_fourth:first-of-type + .frm_half, .frm_half:first-of-type +
.frm_fourth,
.frm_fourth:first-of-type +
.frm_fourth {
  margin-top: 0; }

.frm_half.frm_first,
.frm_fourth.frm_first {
  padding-left: 0; }
  .frm_form_field + .frm_half.frm_first, .frm_form_field +
  .frm_fourth.frm_first {
    margin-top: 15px; }

.frm_half {
  width: 50%; }
  .breakpoint-small .frm_half {
    width: 100%;
    padding-left: 0; }
  .breakpoint-small :first-of-type + .frm_half {
    margin-top: 15px; }

.frm_fourth {
  width: 25%; }
  .breakpoint-small .frm_fourth {
    width: 100%;
    padding-left: 0; }
  .breakpoint-small :first-of-type + .frm_fourth {
    margin-top: 15px; }

/********************************************************************************
	Buttons
 *******************************************************************************/
.button,
.button:visited {
  z-index: 1;
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 11px 28px 11px;
  border: 0;
  border-radius: 5px;
  line-height: 22px;
  font-size: 16px;
  font-family: "PF Din Display W01", sans-serif;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(130deg, #3e8391, #3e6f91);
  transition: all 0.2s 0s;
  cursor: pointer; }
  .button:before,
  .button:visited:before {
    content: '';
    opacity: 0;
    z-index: -1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(130deg, #49abb5, #5294c1);
    transition: all 0.2s 0s; }
  .button[disabled],
  .button:visited[disabled] {
    cursor: default; }
  .button:hover, .button:focus, .button.is-active,
  .button:visited:hover,
  .button:visited:focus,
  .button:visited.is-active {
    outline: 0;
    color: #fff; }
    .button:hover:before, .button:focus:before, .button.is-active:before,
    .button:visited:hover:before,
    .button:visited:focus:before,
    .button:visited.is-active:before {
      opacity: 1; }
  .button.-cancel,
  .button:visited.-cancel {
    color: #3e8391;
    background: linear-gradient(-30deg, #f9f9f9, #ffffff);
    box-shadow: 0px 0px 11px 2px rgba(153, 160, 160, 0.16); }
    .button.-cancel:before,
    .button:visited.-cancel:before {
      background: #fff; }
  .button[disabled],
  .button:visited[disabled] {
    cursor: default;
    pointer-events: none;
    color: #f5f5f5;
    box-shadow: 0px 0px 11px 2px rgba(181, 181, 181, 0.42); }
    .button[disabled]:before,
    .button:visited[disabled]:before {
      opacity: 1;
      background: linear-gradient(-30deg, #d9d6dc, #bcc0d6) !important; }
    .button[disabled] i,
    .button:visited[disabled] i {
      opacity: 0;
      width: 0;
      padding: 0; }
  .breakpoint-small .button, .breakpoint-small
  .button:visited {
    padding: 11px 28px 11px;
    font-size: 14px; }

/********************************************************************************
	Layout
 *******************************************************************************/
.on-mobile {
  display: none !important; }
  .breakpoint-small .on-mobile {
    display: block !important; }

.on-mobile-flex {
  display: none !important; }
  .breakpoint-small .on-mobile-flex {
    display: flex !important; }

.breakpoint-small .on-not-mobile {
  display: none !important; }

body {
  position: relative;
  background: #eee; }

.wrap {
  min-height: 100vh;
  padding: 30px; }
  .breakpoint-medium-small .wrap,
  .breakpoint-small .wrap {
    padding: 0; }
  .wrap > .-inner {
    overflow: hidden;
    display: flex;
    height: 100%;
    min-height: calc( 100vh - 60px);
    border-radius: 5px;
    background: #fff; }
    .wrap > .-inner > main {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px 30px 21px; }
      .breakpoint-large-max .wrap > .-inner > main {
        flex: 0 0 100%; }
      .wrap > .-inner > main .login {
        width: 100%;
        max-width: 350px; }
        .wrap > .-inner > main .login .logo {
          width: 80%;
          height: auto; }
    .wrap > .-inner > aside {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column; }
      .breakpoint-large-max .wrap > .-inner > aside {
        display: none; }
      .wrap > .-inner > aside .-bg {
        flex: 1 0 auto;
        position: relative; }
        .wrap > .-inner > aside .-bg img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          font-family: 'object-fit: cover;'; }
      .wrap > .-inner > aside .-text {
        padding: 24px 30px 21px;
        background: #3e8391; }
        .wrap > .-inner > aside .-text p {
          max-width: 500px;
          text-align: center;
          color: #fff; }

.login {
  text-align: center; }
  .login p {
    text-align: center; }

.auth0-lock.auth0-lock .auth0-lock-widget {
  width: 100%; }

.auth0-lock-header {
  display: none; }

body .auth0-lock.auth0-lock {
  width: 100% !important; }
  body .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 100% !important;
    max-width: 500px; }
  body .auth0-lock.auth0-lock .auth0-lock-header-avatar {
    top: -80px;
    left: auto;
    right: 0;
    width: 70px;
    height: 70px;
    margin: auto;
    border-radius: 5px; }
  body .auth0-lock.auth0-lock .auth0-lock-cred-pane {
    padding: 10px;
    background: #f7f7f7; }
  body .auth0-lock.auth0-lock .auth0-lock-submit {
    margin-top: 30px;
    border-radius: 5px;
    background: #f26700 !important; }
  body .auth0-lock.auth0-lock .auth0-lock-content {
    padding: 0; }
  body .auth0-lock.auth0-lock .auth0-lock-tabs-container {
    display: none; }
  body .auth0-lock.auth0-lock .auth0-lock-tabs {
    border: 0; }
    body .auth0-lock.auth0-lock .auth0-lock-tabs li a {
      padding: 3px 10px;
      font-size: 15px; }
    body .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current {
      background: none; }
      body .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current a {
        color: #f26700; }
  body .auth0-lock.auth0-lock .auth0-lock-social-buttons-container {
    overflow: hidden; }
  body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button {
    float: left;
    width: 49%;
    margin: 0; }
    body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button:nth-child(odd) {
      margin-right: 1%; }
    body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button:nth-child(even) {
      margin-left: 1%; }
    body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button[data-provider="auth0"] {
      background: #39383A; }
    body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button .auth0-lock-social-button-icon {
      width: 45px; }
  body .auth0-lock.auth0-lock .auth0-lock-last-login-pane {
    margin: 20px 0; }
    body .auth0-lock.auth0-lock .auth0-lock-last-login-pane .auth0-lock-social-button.auth0-lock-social-big-button {
      float: none;
      margin: auto !important; }
      body .auth0-lock.auth0-lock .auth0-lock-last-login-pane .auth0-lock-social-button.auth0-lock-social-big-button + p {
        margin: 20px auto 0 !important; }
  body .auth0-lock.auth0-lock .auth0-lock-pane-separator {
    padding-top: 21px !important; }
  body .auth0-lock.auth0-lock .auth0-lock-input-wrap {
    border-radius: 4px;
    background: #f26700; }
    body .auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-input-wrap-with-icon {
      padding-left: 45px; }
    body .auth0-lock.auth0-lock .auth0-lock-input-wrap.auth0-lock-focused {
      border-color: #f1f1f1; }
    body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon,
    body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-custom-icon {
      top: 17px;
      left: 16px; }
      body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon path,
      body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-custom-icon path {
        fill: #fff; }
    body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon.auth0-lock-icon-box {
      bottom: 19px;
      left: 16px; }
    body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input {
      height: 50px;
      border: 1px solid transparent;
      font-size: 15px;
      background: #fff;
      transition: border-color 0.8s; }
      body .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-input:focus {
        border-color: #f26700; }
  body .auth0-lock.auth0-lock .auth0-lock-error-msg {
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1); }
    body .auth0-lock.auth0-lock .auth0-lock-error-msg:after {
      box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1); }
  body .auth0-lock.auth0-lock .auth0-global-message {
    margin: 0 0 10px;
    border-radius: 4px; }
  body .auth0-lock.auth0-lock .auth0-lock-badge-bottom {
    display: none; }

body .post-content .auth0-lock.auth0-lock .auth0-lock-header-avatar {
  top: 11px;
  right: 10px;
  width: 110px;
  height: 110px; }

.login-form {
  position: relative; }
  .with-sso .login-form .-inner {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s; }
  .is-finished-auth-check .login-form .-inner {
    opacity: 1;
    pointer-events: all; }
  .login-form input, .login-form button {
    margin: 0; }

.authenticating {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  margin: auto;
  transition: opacity 0.3s; }
  .is-finished-auth-check .authenticating {
    opacity: 0;
    pointer-events: none; }
  .authenticating .-loader {
    width: 70px;
    margin: 0 auto 30px; }
    .authenticating .-loader > div {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background: #f26700;
      animation: login-bouncedelay 1.4s infinite ease-in-out both; }
    .authenticating .-loader .-a {
      animation-delay: -0.32s; }
    .authenticating .-loader .-b {
      animation-delay: -0.16s; }

@keyframes login-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }
