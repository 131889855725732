/********************************************************************************
	Buttons
 *******************************************************************************/
.button,
.button:visited {
	z-index: 1;
	display: inline-block;
	position: relative;
	width: 100%;
	padding: $spacing-content-small-top 28px $spacing-content-small-bottom;
	border: 0;
	border-radius: $border-radius-base;
	line-height: 22px;
	font-size: 16px;
	font-family: $font-family-headings;
	font-weight: 500;
	color: #fff;
	background: linear-gradient( 130deg, $brand-primary, #3e6f91 );
	transition: $transition-base;
	cursor: pointer;

	&:before {
		content: '';
		opacity: 0;
		z-index: -1;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: $border-radius-base;
		background: linear-gradient(130deg, #49abb5, #5294c1);
		transition: $transition-base;
	}

	&[disabled] {
		cursor: default;
	}

	&:hover,
	&:focus,
	&.is-active {
		outline: 0;
		color: #fff;

		&:before {
			opacity: 1;
		}
	}

	&.-cancel {
		color: $button-bg-primary;
		background: linear-gradient(-30deg, #f9f9f9, #ffffff);
		box-shadow: 0px 0px 11px 2px rgba(153, 160, 160, 0.16);

		&:before {
			background: #fff;
		}
	}

	&[disabled] {
		cursor: default;
		pointer-events: none;
		color: #f5f5f5;
		box-shadow: 0px 0px 11px 2px rgba(181, 181, 181, 0.42);

		&:before {
			opacity: 1;
			background: linear-gradient(-30deg, #d9d6dc, #bcc0d6) !important;
		}

		i {
			opacity: 0;
			width: 0;
			padding: 0;
		}
	}

	.breakpoint-small & {
		padding: $spacing-content-small-top 28px $spacing-content-small-bottom;
		font-size: 14px;
	}
}
