$brand-primary:                 #f26700;
$adventist-charcoal: 			#39383A;

.auth0-lock.auth0-lock .auth0-lock-widget {
	width: 100%;
}

.auth0-lock-header {
	display: none;
}

body {
	.auth0-lock.auth0-lock {
		width: 100% !important;

		.auth0-lock-widget {
			width: 100% !important;
			max-width: 500px;
		}

		.auth0-lock-header-avatar {
			top: -80px;
			left: auto;
			right: 0;
			width: 70px;
			height: 70px;
			margin: auto;
			border-radius: 5px;
		}

		.auth0-lock-cred-pane {
			padding: 10px;
			background: #f7f7f7;
		}

		.auth0-lock-submit {
			margin-top: 30px;
			border-radius: 5px;
			background: $brand-primary !important;
		}

		.auth0-lock-content {
			padding: 0;
		}

		.auth0-lock-tabs-container {
			display: none;
		}

		.auth0-lock-tabs {
			border: 0;

			li {
				a {
					padding: 3px 10px;
					font-size: 15px;
				}

				&.auth0-lock-tabs-current {
					background: none;

					a {
						color: $brand-primary;
					}
				}
			}
		}

		.auth0-lock-social-buttons-container {
			overflow: hidden;
		}

		.auth0-lock-social-button.auth0-lock-social-big-button {
			float: left;
			width: 49%;
			margin: 0;

			&:nth-child(odd) {
				margin-right: 1%;
			}

			&:nth-child(even) {
				margin-left: 1%;
			}

			&[data-provider="auth0"] {
				background: $adventist-charcoal;
			}

			.auth0-lock-social-button-icon {
				width: 45px;
			}
		}

		.auth0-lock-last-login-pane {
			margin: 20px 0;

			.auth0-lock-social-button.auth0-lock-social-big-button {
				float: none;
				margin: auto !important;

				+ p {
					margin: 20px auto 0 !important;
				}
			}
		}

		.auth0-lock-pane-separator {
			padding-top: 21px !important;
		}

		.auth0-lock-input-wrap {
			border-radius: 4px;
			background: $brand-primary;

			&.auth0-lock-input-wrap-with-icon {
				padding-left: 45px;
			}

			&.auth0-lock-focused {
				border-color: #f1f1f1;
			}

			.auth0-lock-icon,
			.auth0-lock-custom-icon {
				top: 17px;
				left: 16px;

				path {
					fill: #fff;
				}
			}

			.auth0-lock-icon.auth0-lock-icon-box {
				bottom: 19px;
				left: 16px;
			}

			.auth0-lock-input {
				height: 50px;
				border: 1px solid transparent;
				font-size: 15px;
				background: #fff;
				transition: border-color 0.8s;

				&:focus {
					border-color: $brand-primary;
				}
			}
		}

		.auth0-lock-error-msg {
			box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);

			&:after {
				box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
			}
		}

		.auth0-global-message {
			margin: 0 0 10px;
			border-radius: 4px;
		}

		.auth0-lock-badge-bottom {
			display: none;
		}
	}

	.post-content {
		.auth0-lock.auth0-lock {
			.auth0-lock-header-avatar {
				top: 11px;
				right: 10px;
				width: 110px;
				height: 110px;
			}
		}
	}
}